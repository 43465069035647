<template>
	<div id="app">
		<section class="landing nopadding">
			<div class="top"></div>
			<div class="bottom"></div>
			<div class="btn" @click="gotogp()"></div>
		</section>
	</div>
</template>

<script>
import "@/css/single.scss";

export default {
	name: "pushes_lat",
	
	data() {
		return {
			pageName: "pushes_lat",
			apk: "https://www.cdns.world/vml/apk/launch/vml-release-5.0622.apk",
			from: "",
			filename: "",
			pkgName: null,

			schemaDownload: false,
			auto: false,
		};
	},
	created() {
		this.from = this.$global.GetQueryString("from")
			? this.$global.GetQueryString("from")
			: this.pageName;
		this.filename = this.$global.GetQueryString("filename")
			? this.$global.GetQueryString("filename")
			: this.pageName;
		this.auto = this.$global.GetQueryString("auto")
			? JSON.parse(this.$global.GetQueryString("auto"))
			: false;
		// this.getAPK();
	},
	mounted() {
		this.$eventrack(`in_page_${this.pageName}`);
	},
	methods: {
		gotogp(){
			window.location.href = 'https://play.google.com/store/apps/details?id=com.video.downloader.vidtube.pro'
		},	
		childFunc(parms) {
			console.log(parms); //接受参数
			if (parms.func == "download") {
				this.downloadfrom(parms.query.downloadfrom);
			}
			if (parms.func == "report") {
				this.$eventrack(parms.query ? parms.query.message : "report");
			}
		},
		getAPK() {
			try {
				this.$api
					.getAPK({
						from: this.from,
						filename: this.filename,
					})
					.then((res) => {
						console.log(res);
						if (res) {
							this.apk = res.apk;
							this.pkgName = `${res.version}_${this.filename}`;
							this.schemaDownload = true;
						} else {
							this.pkgName = `000_${this.filename}`;
						}
						this.$eventrack(`apk_init_${this.pageName}`, "browse", {
							channel: this.from,
							apk_full_path: this.apk,
							pkgName: this.pkgName,
						});
					});
			} catch (error) {
				// 捕获异常并进行处理
				console.error("发生错误：", error);
				this.$eventrack(`apk_init_fail_${this.pageName}`);
			}
		},
		downloadfrom(reason) {
			this.download();
			this.$eventrack(
				`download_${this.pageName}_from_${reason}`,
				"download"
			);
		},
		download() {
			this.$global.download(this.apk);
		},
	},
};
</script>
